(function ($) {
  // When the menu changes, trigger skuSelect:
  $(document).on('change', 'select.js-sku-menu', function () {
    var skuId = $(this).val();
    var sku = prodcat.data.getSku(skuId);
    var skuBaseId;

    if (sku) {
      skuBaseId = sku.SKU_BASE_ID;
      $(this).closest('.js-product').trigger('product.skuSelect', [skuBaseId]);
    }
  });

  // Likewise, whenever skuSelect is fired on a product, update the menu:
  $('.js-product').on('product.skuSelect', function (event, skuBaseId) {
    $('select.js-sku-menu', this).val(skuBaseId);
    $('select.js-sku-menu.selectBox-attached', this).selectBox('value', skuBaseId);
  });
})(jQuery);
